import { Component, ViewEncapsulation, Input, forwardRef, ViewChild, ElementRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { BaseControlValueAccessor } from '../../models/forms/base-control-value-accessor';

@Component({
    selector: 'radio-button',
    templateUrl: './radio-button.component.html',
    encapsulation: ViewEncapsulation.None,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => RadioButtonComponent),
            multi: true
        }
    ]
})

export class RadioButtonComponent extends BaseControlValueAccessor<any> {
    @Input() imageSrc: string = '';
    @Input() imageRef: string = '';
    @Input() imageAlt: string = '';
    @Input() label: string = '';
    @Input() description: string = '';
    @Input() internalValue: any;
    @Input() title: boolean = false;
    @Input() price: boolean = false;

    @Input() set disabled(value: boolean) {
        this._disabled = value;
        this.setDisabled()
    }

    @Input() set readonly(value: boolean) {
        this._readonly = value;
        this.setDisabled();
    }

    @Input() name: string;

    @ViewChild('radioButton', {static: true}) radioButtonElement: ElementRef;

    ngOnInit(): void {
        super.ngOnInit();

        if (!this.formControlName && !this.name && this.formControl) {
            throw new Error("If you want to specify a formControl directly then that is fien but you must still specify a 'name' input for the browser to understand which radio buttons work together. This name needs to be unique inside the form");
        }

        this.setChecked(this.value);
        this.disabled = this._disabled;
    }

    internalOnChange(event) {
        this.onChange(this.internalValue);
    }

    writeValue(value: any): void {
        this.setChecked(value);
        super.writeValue(value);
    }

    private setChecked(value: any) {
        if (this.radioButtonElement && this.radioButtonElement.nativeElement) {
            if (value === this.internalValue) {
                this.radioButtonElement.nativeElement.checked = true;
            } else {
                this.radioButtonElement.nativeElement.removeAttribute('checked');
                this.radioButtonElement.nativeElement.checked = false;
            }
        }
    }

    setDisabledState(isDisabled: boolean): void {
        this._disabled = isDisabled;
        this.setDisabled();
    }

    private setDisabled() {
        if (this.radioButtonElement && this.radioButtonElement.nativeElement) {
            if (this._readonly || this._disabled) {
                this.radioButtonElement.nativeElement.setAttribute('disabled', true);
            } else {
                this.radioButtonElement.nativeElement.removeAttribute('disabled');
            }
        }
    }
}
