<label class="form__radio-button" [class.price]="price" [class.disabled]="disabled" [class.read-only]="readonly || disabled" [class.required]="touched && invalid" >
    <input type="radio" [value]="internalValue" [name]="(name ? name : formControlName)" (change)="internalOnChange($event)" #radioButton>
    <div class="form__radio-button-label">
        <div class="form__button-content u-text-align--center" *ngIf="imageSrc || imageRef">
            <ng-container *ngIf="imageSrc">
                <img [src]="imageSrc" [alt]="imageAlt" class="img--fluid">
            </ng-container>
            <ng-container *ngIf="imageRef">
                <sprite-image [reference]="imageRef" defaultImage="" [alt]="imageAlt"></sprite-image>
            </ng-container>
            <h4 *ngIf="title">{{label}}</h4>
            <p *ngIf="!title">{{label}}</p>
            <p class="u-text--small radio-button-description" *ngIf="description">{{description}}</p>
        </div>
        <ng-container *ngIf="!imageSrc && !imageRef">
            <ng-container *ngIf="title">
                <ng-container *ngIf="!price">
                    <h4 class="form__radio-button-label-title">{{label}}</h4>
                    <p *ngIf="description" class="u-text--small radio-button-description">{{description}}</p>
                </ng-container>
                <ng-container *ngIf="price">
                    <p class="text__price"><strong>{{label}}</strong> plus VAT</p>
                    <p *ngIf="description" class="u-text--small">{{description}}</p>
                </ng-container>
            </ng-container>
            <ng-container *ngIf="!title">
                {{label}}
            </ng-container>
        </ng-container>
    </div>
</label>
