import { Injectable } from "@angular/core";
import {AbstractControl, FormControl, FormGroup, UntypedFormArray, UntypedFormGroup} from "@angular/forms";
import * as _ from 'lodash';
import { UtilitiesService } from "./utilities.service";
import { ErrorSummary } from "../models/error/error-summary";
import {DropdownOption} from "../components/dropdown/dropdown-option";
import {HttpErrorResponse} from "@angular/common/http";
import {ErrorResponseErrors} from "../components/error-response-summary/models/error-response-errors";
import { DateTime } from 'luxon';

@Injectable()
export class FormService {
    constructor(private utilitiesService: UtilitiesService) {
    }

    convertControlsToFormFields(controls: {}, overrides: { [p: string]: string } = {}) {
        const formFields: { [key: string]: AbstractControl } = {};

        Object.keys(controls).forEach(key => {
            const humanKey = overrides[key] ? overrides[key] : this._humanize(key)

            formFields[humanKey] = controls[key] as AbstractControl<any, any>;
        });

        return formFields;
    }

    convertTypedFormControlsToFormFields<T>(form: FormGroup, nameMappings: { [p in keyof T]: string }) {
        const formFields: { [key: string]: AbstractControl } = {};

        Object.keys(form.controls).forEach(key => {
            const humanKey = nameMappings[key]

            formFields[humanKey] = form.controls[key] as AbstractControl<any, any>;
        });

        return formFields;
    }

    _humanize(str) {
        return _.capitalize(_.trim(_.snakeCase(str).replace(/_id$/, '').replace(/_/g, ' ')));
    }

    checkAllControlsAreValid(fields: { [key: string]: AbstractControl }, traverse: boolean = true): ErrorSummary | null {
        const errs = Object.keys(fields).map(key => {
            const field = fields[key];
            if (!field) {
                return;
            }
            const errors = this.checkControlIsValid(field, key, traverse);
            if (errors) {
                console.log(key, field, errors);
            }
            return errors;
        }).filter(m => !!m);

        const combinedErrors = new ErrorSummary();
        errs.forEach(errArray => {
            if (!errArray) {
                return;
            }
            errArray.forEach(err => {
                combinedErrors.push(err);
            });
        });

        if (!_.isEmpty(combinedErrors)) {
            return combinedErrors;
        }

        return null;
    }

    checkControlIsValid(control: AbstractControl, controlName?: string, traverse: boolean = true): ErrorSummary {
        this.resetFormValidation(control);
        control.markAllAsTouched();

        let formControlKey = '';
        if (control.parent) {
            formControlKey = Object.keys(control.parent.controls).find(key => control.parent.controls[key] === control);
        } else if (control instanceof UntypedFormGroup) {
            formControlKey = Object.keys(control.controls).find(key => control.controls[key] === control);
        }

        if (!controlName) {
            controlName = formControlKey;
        }

        const errors = [];
        if (!control.valid && control.errors) {
            const e = {};
            e[controlName] = control;
            errors.push(e);
        }

        if(control.value instanceof DateTime){
            if(!control.value.isValid){
                const e = {};
                e[controlName] = control;
                errors.push(e);
            }
        }

        if (traverse && control instanceof UntypedFormGroup) {
            errors.push(Object.keys(control.controls)
                .map(key => {
                    const c = control.controls[key];
                    return this.checkControlIsValid(c, `${controlName} > ${key}`);
                })
            );
        } else if (traverse && control instanceof UntypedFormArray) {
            errors.push((control as UntypedFormArray).controls.map((c, i) => {
                return this.checkControlIsValid(c, controlName);
            }));
        }

        const flattened: [] = this.utilitiesService.flatten(errors)
            .filter(err => !!err);

        if (_.isEmpty(flattened)) {
            return null;
        }
        return flattened;
    }

    resetFormValidation(control: AbstractControl) {
        control.updateValueAndValidity({onlySelf: true, emitEvent: false});

        if (control instanceof UntypedFormGroup) {
            Object.keys(control.controls)
                .forEach(key => {
                    const c = control.controls[key];
                    this.resetFormValidation(c);
                });
        } else if (control instanceof UntypedFormArray) {
            (control as UntypedFormArray).controls.forEach(c => {
                this.resetFormValidation(c);
            });
        }
    }

    // All items within 'components' need to have a getFormFieldsForErrors() method or must be null
    mergeFormFieldsForErrors(...components): {[p: string]: AbstractControl} {
        if (components.find(component => component && component.hasOwnProperty('getFormFieldsForErrors') && component.prototype.hasOwnProperty('getFormFieldsForErrors') == false)) throw new Error("Cannot execute on a component that does not have a getFormFieldsForErrors method");
        if (!components) throw new Error("Components must be non-null");

        const allFields = {};
        components.forEach(component => {
           if (!component) return;

           const fields = component.getFormFieldsForErrors();

           Object.keys(fields).forEach(fieldName => allFields[fieldName] = fields[fieldName]);
        });

        return allFields;
    }

    // Sorts a list of dropdown options in place using locale settings to compare characters with accents etc..
    // properly. Does a case insensitive sort
    sortDropdownOptions(list: DropdownOption[]): DropdownOption[] {
        list.sort((a, b) =>  a.label.localeCompare(b.label));

        // Return the list even though it is sorted in place to be consistent with 'sort()'
        return list;
    }

    // Sorts a list of dropdown options in place using locale settings to compare characters with accents etc..
    // properly. Does a case insensitive sort by groupValue and then label. This will only work for lists where
    // you are not using a translation table
    sortDropdownOptionsWithGroupValues(list: DropdownOption[]): DropdownOption[] {
        // Sort is a stable sort so the order of the first source will be retained within the second
        list.sort((a, b) => a.label.localeCompare(b.label));
        list.sort((a, b) => a.groupValue.localeCompare(b.groupValue));

        // Return the list even though it is sorted in place to be consistent with 'sort()'
        return list;
    }

    // Helper method to limit a pre-created dropdown list to specific options
    //   * For very large lists (limitTo > 50) we might want to make a hash and rather do lookups
    //     than using 'includes' which is a loop within a loop.
    limitDropdownOptions(options: DropdownOption[], limitTo: any[]) {
        return options.filter(x => limitTo.includes(x.value));
    }

    enumToDropdownList(enumName, enumReverse: any = null): DropdownOption[] {
        const keys = this.enumKeys(enumName);

        if (enumReverse) {
            return keys.map((key, index, array) => {
                return new DropdownOption(enumReverse[key], key);
            });
        }

        return keys.map((key, index, array) => {
            return new DropdownOption(enumName[key], key);
        });
    }

    dictionaryToDropdownList(dictionary): DropdownOption[] {
        const keys = Object.keys(dictionary);

        var options = keys.map((key, index, array) => {
            return new DropdownOption(dictionary[key], key);
        });

        options.sort();

        return options;
    }

    enumKeys(enumName): string[] {
        return Object.keys(enumName).filter(item => !isNaN(parseInt(item)));
    }

    isModelStateErrorsPostCommit(response: any) : boolean {
        return isModelStateErrorsPostCommit(response);
    }

    extractModelStateErrors(response: any) : ErrorResponseErrors | null {
        return responseExtractModelStateErrors(response);
    }

    createModelStateErrorsFromString(error: string) {
        return {'error': [error]};
    }

    constructModelStateErrors(errorStrings: string | string[], jsonObject: any = null): ErrorResponseErrors {
        let responseArray: string[] = null;
        let hiddenTextArray: string[] = [];

        if (!errorStrings) responseArray = ["Unspecified error"];
        else if (!Array.isArray(errorStrings)) responseArray = [errorStrings];
        else responseArray = [...errorStrings];

        if (jsonObject) hiddenTextArray = [JSON.stringify(jsonObject)];

        return {
            "response": responseArray,
            "_hidden_": hiddenTextArray,
        }
    }


    addPrefixToFormFields(formFields: {}, prefix: string | null, separator: string = ' ') : {} {
        if (!prefix) return {...formFields};

        const newFormFields = {};

        Object.keys(formFields).forEach(key => {
            const newKey = key.charAt(0).toLowerCase() + key.slice(1);

           newFormFields[prefix + separator + newKey] = formFields[key];
        });

        return newFormFields;
    }


}



// Takes an interface and wraps each field (K) in to become AbstractControl<K>
// Input `interface MyInterface { name: string };` well then allow you to define `type myFormModel = GenericFormModel<MyInterface>;`
//    and it will be the equilvalent of `interface MyInterface { name: FormControl<string>; }`
export type GenericFormModel<T> = {
    [K in keyof T]: NonNullable<T[K]> extends unknown[]
        ? FormControl<T[K]>
        : FormControl<T[K] | null>;
};

export type Partial<T> = { [P in keyof T]?: T[P]; }


export function isModelStateErrorsPostCommit(response: any) : boolean {
    if (typeof response == 'object' && response instanceof HttpErrorResponse) {
        var httpErrorResponse = response as HttpErrorResponse;

        // Handle specific public exception format passed back from server (any HTTP Code) or HTTP code 400 which means BadRequest and it should follow the correct format
        if (response && typeof response  == 'object' && 'error' in response && httpErrorResponse.error && typeof response.error == 'object' && 'isPostCommit' in httpErrorResponse.error) {
            return httpErrorResponse.error.isPostCommit;
        }
    }

    return false;
}

export function responseExtractModelStateErrorsAsString(response: any) : string | null {
    const responseErrors = responseExtractModelStateErrors(response);
    if (!responseErrors) return null;

    const keys = Object.keys(responseErrors).filter(keyName => Array.isArray(responseErrors[keyName]) && keyName != '_hidden_' && keyName != 'isPublicException' && keyName != 'isPostCommit');
    let joinedString = keys.map(k => responseErrors[k].join(". ")).join(". ");

    // Remove duplicate fullstops and return as a string
    return joinedString.replace("..", ".").replace(". .", ".");
}


export function responseExtractDebugModelStateErrorsAsString(response: any) : string | null {
    const responseErrors = responseExtractModelStateErrors(response);
    if (!responseErrors) return null;

    const keys = Object.keys(responseErrors).filter(keyName => Array.isArray(responseErrors[keyName]) && keyName == '_hidden_');
    let joinedString = keys.map(k => responseErrors[k].join(". ")).join(". ");

    // Remove duplicate fullstops and return as a string
    return joinedString.replace("..", ".").replace(". .", ".");
}

export function responseExtractModelStateErrors(response: any) : ErrorResponseErrors | null {
    if (typeof response == 'object' && response instanceof HttpErrorResponse) {
        var httpErrorResponse = response as HttpErrorResponse;
        // Handle specific public exception format passed back from server (any HTTP Code) or HTTP code 400 which means BadRequest and it should follow the correct format
        if (httpErrorResponse.hasOwnProperty('error') && httpErrorResponse.error && typeof response.error == 'object' && (httpErrorResponse.error.hasOwnProperty('isPublicException') || httpErrorResponse.status == 400)) {

            // Handle specific format sometimes returned by API when there is something wrong with the signature before it reaches the API call (so maybe the Guid isn't provided)
            if (httpErrorResponse.error.hasOwnProperty('errors') && typeof httpErrorResponse.error.errors == 'object') return httpErrorResponse.error.errors as ErrorResponseErrors;

            // Handle format of NotFound(string) or BadRequest(ModelState)
            return (httpErrorResponse.error as ErrorResponseErrors);
        }

        if (!httpErrorResponse.status) {
            return {
                "response": ["The server did not respond or timed out. Please check your Internet connection and if the issue persists contact support@coho.life"],
                "_hidden_": ["Message: " + httpErrorResponse.message],
            }
        }

        if (httpErrorResponse.status == 404) {
            if (httpErrorResponse.error && _.isString(httpErrorResponse.error)){
                return {
                    "response": [httpErrorResponse.error],
                    "_hidden_": ["Status: " + httpErrorResponse.status + ", Url: " + httpErrorResponse.url],
                }
            } else {
                return {
                    "response": ["The requested resource was not found"],
                    "_hidden_": ["Status: " + httpErrorResponse.status + ", Url: " + httpErrorResponse.url, JSON.stringify(httpErrorResponse.error)],
                }
            }
        }


        if (httpErrorResponse.status == 401) {
            if (httpErrorResponse.error && _.isString(httpErrorResponse.error)){
                return {
                    "response": [httpErrorResponse.error],
                    "_hidden_": ["Status: " + httpErrorResponse.status + ", Url: " + httpErrorResponse.url],
                }
            } else {
                return {
                    "response": ["You are not allowed to access the requested resource. It may be that your user account does not have the correct permissions."],
                    "_hidden_": ["Status: " + httpErrorResponse.status + ", Url: " + httpErrorResponse.url, JSON.stringify(httpErrorResponse.error)],
                }
            }
        }

        if (httpErrorResponse.status == 413) {
            if (httpErrorResponse.error && _.isString(httpErrorResponse.error)){
                return {
                    "response": [httpErrorResponse.error],
                    "_hidden_": ["Status: " + httpErrorResponse.status + ", Url: " + httpErrorResponse.url],
                }
            } else {
                return {
                    "response": ["You tried to submit a too large file or content exceeding our limit"],
                    "_hidden_": ["Status: " + httpErrorResponse.status + ", Url: " + httpErrorResponse.url, JSON.stringify(httpErrorResponse.error)],
                }
            }
        }

        if (httpErrorResponse.status == 500) {

            if (httpErrorResponse.error) {
                return {
                    "response": ["Unexpected server error occurred."],
                    "_hidden_": ["Status: " + httpErrorResponse.status + ", Url: " + httpErrorResponse.url + "<br/>\n\n" + (httpErrorResponse.error.hasOwnProperty("exception") ? httpErrorResponse.error.exception + " = " + httpErrorResponse.error.message : "")],
                }
            } else {
                return {
                    "response": ["Unexpected server error occurred."],
                    "_hidden_": ["Status: " + httpErrorResponse.status + ", Url: " +  httpErrorResponse.url],
                }
            }
        }

        if (httpErrorResponse.status == 504) {
            if (httpErrorResponse.error) {
                return {
                    "response": ["This is taking too long but will eventually complete. Please reload the page to see your data. You may have to wait a few minutes if it changes do not show right away."],
                    "_hidden_": ["Status: " + httpErrorResponse.status + ", Url: " + httpErrorResponse.url + "Error: " + httpErrorResponse.error],
                }
            } else {
                return {
                    "response": ["This is taking too long but will eventually complete. Please reload the page to see your data. You may have to wait a few minutes if it changes do not show right away."],
                    "_hidden_": ["Status: " + httpErrorResponse.status + ", Url: " +  httpErrorResponse.url],
                }
            }
        }

        if (httpErrorResponse.error){
            return {
                "response": [httpErrorResponse.error],
                "_hidden_": ["Status: " + httpErrorResponse.status + ", Url: " + httpErrorResponse.url],
            }
        } else {
            return {
                "response": ["An error has occured. Please try again, and if the error persists contact support@coho.life giving the following information: " + httpErrorResponse.status + " - " + httpErrorResponse.statusText ],
                "_hidden_": ["Status: " + httpErrorResponse.status + ", Url: " + httpErrorResponse.url + "     Message: " + httpErrorResponse.message],
            }
        }
    }

    if (response instanceof DOMException) {
        console.log("Unknown error format", response);
        return {
            "response": ["A browser error has occured. Please try again, and if the error persists contact support@coho.life giving the following information: "  + response.name + " with message: " + response.message],
            "_hidden_": [JSON.stringify(response)],
        }
    }


    // If you came this far you need to write new code to handle this new error format and transform it into something friendly.
    console.log(response);
    return {
        "response": ["Something went wrong. Please try again, and if the error persists contact support@coho.life.", JSON.stringify(response)],
        "_hidden_": ["Message: " + JSON.stringify(response)],
    }
}
