import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';

import { ConfigService } from './config.service';
import { FileService } from "./file.service";
import { OrganisationTenancyAgreementTemplate } from '../models/tenancy-agreement/organisation-tenancy-agreement-template';
import {
    OrganisationTenancyAgreementPost,
} from '../models/tenancy-agreement/organisation-tenancy-agreement-post';
import {TemplateMergeFieldsValidation} from "../models/tenancy-agreement/template-merge-fields-validation.interface";
import {
    TenancyAgreementTemplateMergeFieldsValidationPost
} from "../models/tenancy-agreement/template-validation-post.interface";
import {mergeMap, tap} from "rxjs/operators";


@Injectable()
export class TenancyAgreementTemplateService {
    constructor(private http: HttpClient, private configService: ConfigService, private fileService: FileService) {
    }

    getServiceApiRoute(organisationReference: string){
        return `${this.configService.baseUrl}/manage/organisation/${organisationReference}/tenancy-agreement-templates`;
    }

    getTemplates(organisationReference: string, propertyReference?: string) {
        let params = new HttpParams();
        if (propertyReference) {
            params = params.append('propertyReference', propertyReference);
        }
        return this.http.get<OrganisationTenancyAgreementTemplate[]>(this.getServiceApiRoute(organisationReference), { params: params });
    }

    getSampleTags(organisationReference: string) {
        return this.http.get<{
            sampleDictionary :{[p: string]: string};
            draftDictionary :{[p: string]: string};
        }>(this.getServiceApiRoute(organisationReference) + '/sample-mailmerge-tags');
    }

    add(organisationReference: string, model: OrganisationTenancyAgreementPost) {
        let obs = of([]);

        if (model.tenancyAgreementFiles) {
            obs = obs.pipe(
                mergeMap(_ => this.fileService.uploadFiles(model.tenancyAgreementFiles)),
                tap(_ => model.tenancyAgreementFiles = this.fileService.prepareFilePosts(model.tenancyAgreementFiles)));
        }
        if (model.supportingFiles) {
            obs = obs.pipe(
                mergeMap(_ => this.fileService.uploadFiles(model.supportingFiles)),
                tap(_ => model.supportingFiles = this.fileService.prepareFilePosts(model.supportingFiles)));
        }
        if (model.guarantorFiles) {
            obs = obs.pipe(
                mergeMap(_ => this.fileService.uploadFiles(model.guarantorFiles)),
                tap(_ => model.guarantorFiles = this.fileService.prepareFilePosts(model.guarantorFiles)));
        }

        return obs.pipe(mergeMap(_ => this.http.post<OrganisationTenancyAgreementTemplate>(this.getServiceApiRoute(organisationReference), model)));
    }

    delete(organisationReference: string, guid: string) {
        return this.http.delete(this.getServiceApiRoute(organisationReference) + `/${guid}`);
    }
}
