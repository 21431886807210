import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation} from '@angular/core';
import {NavigateService} from "../../services/navigate.service";
import {AccountService} from "../../services/account.service";
import {ErrorResponseErrors} from "./models/error-response-errors";

@Component({
    selector: 'error-new-response-summary',
    templateUrl: './error-response-summary-new.component.html',
    encapsulation: ViewEncapsulation.None,
    host: {'class': 'error-summary'},
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class ErrorResponseSummaryNewComponent {
    @Input() canTryAgain: boolean = false;
    @Input() canDismiss: boolean = false;
    @Input() hiddenMessages: "show_if_debug" | "show" | "hide" = "show_if_debug";

    _responseErrors: ErrorResponseErrors | null = null;
    @Input() set responseErrors(value: ErrorResponseErrors | null) {
        if (value) {
            console.log(value);
            this._responseErrors = value;
            this._responseKeys = Object.keys(value).filter(keyName => Array.isArray(value[keyName]) && keyName != '_hidden_' && keyName != 'isPublicException' && keyName != 'isPostCommit');
            this._responseHasHidden = !!value['_hidden_'];

            console.log('this._responseErrors', this._responseErrors);
            console.log('this._responseKeys', this._responseKeys);
            console.log('this._responseHasHidden', this._responseHasHidden);
        }
        else
        {
            this._responseErrors = null;
            this._responseKeys = [];
        }
    }

    @Output() onTryAgain = new EventEmitter();

    _responseKeys: string[] = [];
    _responseHasHidden: boolean = false;

    constructor(private navigateService: NavigateService, public accountService: AccountService) {
        if (this.hiddenMessages == "show_if_debug" && accountService.loginHasDebugEnabled()) this.hiddenMessages = "show";
    }

    private getControlKey(keyName: string): string {
        return `[formcontrolname="${keyName}"]`
    }

    goToControl(keyName: string) {
        let formControlKey = this.getControlKey(keyName);
        this.navigateService.scrollToElement(formControlKey);
    }

    controlExists(keyName: string) {
        let formControlKey = this.getControlKey(keyName);
        return document.querySelector(formControlKey) !== null;
    }

    again(){
        this.onTryAgain.emit()
    }

    dismiss() {
        this._responseErrors = null;
    }

}
