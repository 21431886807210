import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';

import { ConfigService } from './config.service';
import { TenancyAgreementPost } from '../models/tenancy-agreement/tenancy-agreement-post';
import { TenancyAgreement } from '../models/tenancy-agreement/tenancy-agreement';
import { FileService } from "./file.service";
import { TenancyAgreementOverview } from '../models/tenancy-agreement/tenancy-agreement-overview';
import { TenancyAgreementConfirmPost } from '../models/tenancy-agreement/tenancy-agreement-confirm-post';
import { TenancyContractReceipt } from '../models/tenancy-agreement/tenancy-contract-receipt';
import { GuarantorTenancyAgreement } from '../models/tenancy-agreement/guarantor-tenancy-agreement';
import { TenanciesOrganisationOverview } from '../models/tenancy-agreement/tenancies-organisation-overview';
import { TenancyStatuses } from '../models/tenancy/tenancy-statuses';
import { TenancySummary } from '../models/tenancy/tenancy-summary';
import moment from 'moment';
import { AccountService } from './account.service';
import { TenancyRoomMovePost } from '../models/tenancy/tenancy-room-move-post';
import {TenancyAgreementGuarantorSignedPost} from "../models/tenancy-agreement/tenancy-agreement-guarantor-signed-post";
import {TenancyAgreementSummary} from "../models/tenancy/tenancy-agreement-summary";
import {PropertyFilterPresets} from "../models/manage-property/property-details";
import {MergeFieldsTranslation} from "../models/tenancy-agreement/merge-fields-translation.interface";
import {AgreementFilePost} from "../models/tenancy-agreement/agreement-file-post.interface";
import {
    TenancyAgreementInformationPost
} from "../models/tenancy-agreement/tenancy-agreement-information-post.interface";
import {AddAdditionalFilesPost} from "../models/tenancy-agreement/add-additional-files-post.interface";
import {DigitalSignatureStatuses} from "../models/tenancy-agreement/digital-signature-statuses";
import {mergeMap, tap} from "rxjs/operators";
import {HouseShareFile} from "../models/file/file";
import {GetTenanciesOfGroupResponse} from "../models/tenancy/get-tenancies-of-group-response";

@Injectable()
export class TenancyAgreementService {
    constructor(private http: HttpClient, private configService: ConfigService, private fileService: FileService, private accountService: AccountService) {
    }

    getOverview(propertyReference: string, roomReference: string, guid: string): Observable<TenancyAgreementOverview> {
        return this.http.get<TenancyAgreementOverview>(`${this.configService.baseUrl}/manage/property/${propertyReference}/room/${roomReference}/tenancy/${guid}/tenancy-agreement/overview`);
    }

    add(organisationReference: string, propertyReference: string, roomReference: string, guid: string, model: TenancyAgreementPost) {
        let obs = of([]);

        if(model.tenancyAgreementInformation){
            if (model.tenancyAgreementInformation.tenancyAgreementFiles) {
                obs = obs.pipe(
                    mergeMap(_ => this.fileService.uploadFiles(model.tenancyAgreementInformation.tenancyAgreementFiles.map(m => m.file) as HouseShareFile[])),
                    tap(_ => {
                        const preparedFiles = this.fileService.prepareFilePosts(model.tenancyAgreementInformation.tenancyAgreementFiles.map(m => m.file) as HouseShareFile[]);
                        model.tenancyAgreementInformation.tenancyAgreementFiles = model.tenancyAgreementInformation.tenancyAgreementFiles.map((agreementFile, index) => ({
                            ...agreementFile,
                            file: preparedFiles[index],
                        }));
                    }));
            }

            if (model.tenancyAgreementInformation.tenancyAgreementSupportingFiles) {
                obs = obs.pipe(
                    mergeMap(_ => this.fileService.uploadFiles(model.tenancyAgreementInformation.tenancyAgreementSupportingFiles.map(m => m.file) as HouseShareFile[])),
                    tap(_ => {
                        const preparedFiles = this.fileService.prepareFilePosts(model.tenancyAgreementInformation.tenancyAgreementSupportingFiles.map(m => m.file) as HouseShareFile[]);
                        model.tenancyAgreementInformation.tenancyAgreementSupportingFiles = model.tenancyAgreementInformation.tenancyAgreementSupportingFiles.map((agreementFile, index) => ({
                            ...agreementFile,
                            file: preparedFiles[index],
                        }));
                    }));
            }

            if (model.tenancyAgreementInformation.guarantorFiles) {
                obs = obs.pipe(
                    mergeMap(_ => this.fileService.uploadFiles(model.tenancyAgreementInformation.guarantorFiles.map(m => m.file) as HouseShareFile[])),
                    tap(_ => {
                        const preparedFiles = this.fileService.prepareFilePosts(model.tenancyAgreementInformation.guarantorFiles.map(m => m.file) as HouseShareFile[]);
                        model.tenancyAgreementInformation.guarantorFiles = model.tenancyAgreementInformation.guarantorFiles.map((agreementFile, index) => ({
                            ...agreementFile,
                            file: preparedFiles[index],
                        }));
                    }));
            }
        }

        const url = `${this.configService.baseUrl}/manage/organisation/${organisationReference}/property/${propertyReference}/room/${roomReference}/tenancy/${guid}/tenancy-agreement`;
        return obs.pipe(mergeMap(_ => this.http.post<TenancyAgreement>(url, model)));
    }

    getMergeFieldsTranslationsForNewTenancyAgreement(organisationReference: string, propertyReference: string, model: TenancyAgreementInformationPost){
        let obs = of([]);

        if (model.tenancyAgreementFiles) {
            obs = obs.pipe(
                mergeMap(_ => this.fileService.uploadFiles(model.tenancyAgreementFiles.map(m => m.file) as HouseShareFile[])),
                tap(_ => {
                    const preparedFiles = this.fileService.prepareFilePosts(model.tenancyAgreementFiles.map(m => m.file) as HouseShareFile[]);
                    model.tenancyAgreementFiles = model.tenancyAgreementFiles.map((agreementFile, index) => ({
                        ...agreementFile,
                        file: preparedFiles[index],
                    }));
                }));
        }

        if (model.tenancyAgreementSupportingFiles) {
            obs = obs.pipe(
                mergeMap(_ => this.fileService.uploadFiles(model.tenancyAgreementSupportingFiles.map(m => m.file) as HouseShareFile[])),
                tap(_ => {
                    const preparedFiles = this.fileService.prepareFilePosts(model.tenancyAgreementSupportingFiles.map(m => m.file) as HouseShareFile[]);
                    model.tenancyAgreementSupportingFiles = model.tenancyAgreementSupportingFiles.map((agreementFile, index) => ({
                        ...agreementFile,
                        file: preparedFiles[index],
                    }));
                }));
        }

        if (model.guarantorFiles) {
            obs = obs.pipe(
                mergeMap(_ => this.fileService.uploadFiles(model.guarantorFiles.map(m => m.file) as HouseShareFile[])),
                tap(_ => {
                    const preparedFiles = this.fileService.prepareFilePosts(model.guarantorFiles.map(m => m.file) as HouseShareFile[]);
                    model.guarantorFiles = model.guarantorFiles.map((agreementFile, index) => ({
                        ...agreementFile,
                        file: preparedFiles[index],
                    }));
                }));
        }

        const url = `${this.configService.baseUrl}/manage/organisation/${organisationReference}/property/${propertyReference}/tenancy-agreement/merge-fields-translations`;
        return obs.pipe(mergeMap(_ => this.http.post<MergeFieldsTranslation[]>(url, model)));
    }

    downloadFilePreview(organisationReference: string, propertyReference: string, model: AgreementFilePost){
        let obs: Observable<any> = of(null);

        if (model.file) {
            obs = obs.pipe(
                mergeMap(_ => this.fileService.uploadFile(model.file)),
                tap(_ => model.file = this.fileService.prepareFilePost(model.file)));
        }

        const url = `${this.configService.baseUrl}/manage/organisation/${organisationReference}/property/${propertyReference}/tenancy-agreement/download-file-preview`;
        return obs.pipe(mergeMap(_ => this.http.post(url, model, {responseType: 'blob'})));
    }

    get(tenancyAgreementGuid: string) {
        const url = `${this.configService.baseUrl}/manage/tenancy-agreements/${tenancyAgreementGuid}`;
        return this.http.get<TenancyAgreement>(url);
    }

    confirm(tenancyAgreementGuid: string, model: TenancyAgreementConfirmPost) {
        let obs = of([]);

        if (model.guarantorManuallySignedFilesWithManagerSignature) {
            obs = obs.pipe(
                mergeMap(_ => this.fileService.uploadFiles(model.guarantorManuallySignedFilesWithManagerSignature)),
                tap(_ => model.guarantorManuallySignedFilesWithManagerSignature = this.fileService.prepareFilePosts(model.guarantorManuallySignedFilesWithManagerSignature)));
        }

        if (model.tenancyAgreementManuallySignedFilesWithManagerSignature) {
            obs = obs.pipe(
                mergeMap(_ => this.fileService.uploadFiles(model.tenancyAgreementManuallySignedFilesWithManagerSignature)),
                tap(_ => model.tenancyAgreementManuallySignedFilesWithManagerSignature = this.fileService.prepareFilePosts(model.tenancyAgreementManuallySignedFilesWithManagerSignature)));
        }

        const url = `${this.configService.baseUrl}/manage/tenancy-agreements/${tenancyAgreementGuid}/confirm`
        return obs.pipe(mergeMap(_ => this.http.patch<TenancyAgreement>(url, model)));
    }

    adminRegenerateSignedContracts(tenancyAgreementGuid: string): any {
        return this.http.get<boolean>(`${this.configService.baseUrl}/manage/tenancy-agreements/${tenancyAgreementGuid}/admin-regenerate-signed-documents`);
    }

    remove(tenancyAgreementGuid: string) {
        const url = `${this.configService.baseUrl}/manage/tenancy-agreements/${tenancyAgreementGuid}`
        return this.http.delete(url);
    }

    addFilesToTenancyAgreement(tenancyAgreementGuid: string, model: AddAdditionalFilesPost) {
        let obs = of([]);

        if (model.tenancyAgreementFiles) {
            obs = obs.pipe(
                mergeMap(_ => this.fileService.uploadFiles(model.tenancyAgreementFiles)),
                tap(_ => model.tenancyAgreementFiles = this.fileService.prepareFilePosts(model.tenancyAgreementFiles)));
        }

        if (model.tenancyAgreementSupportingFiles) {
            obs = obs.pipe(
                mergeMap(_ => this.fileService.uploadFiles(model.tenancyAgreementSupportingFiles)),
                tap(_ => model.tenancyAgreementSupportingFiles = this.fileService.prepareFilePosts(model.tenancyAgreementSupportingFiles)));
        }

        if (model.guarantorFiles) {
            obs = obs.pipe(
                mergeMap(_ => this.fileService.uploadFiles(model.guarantorFiles)),
                tap(_ => model.guarantorFiles = this.fileService.prepareFilePosts(model.guarantorFiles)));
        }

        const url = `${this.configService.baseUrl}/manage/tenancy-agreements/${tenancyAgreementGuid}/attach-additional-files`;

        return obs.pipe(mergeMap(_ => this.http.post<TenancyAgreement>(url, model)));
    }

    getTenancyContractReceipt(guid: string) {
        return this.http.get<TenancyContractReceipt>(`${this.configService.baseUrl}/tenancy-agreement/receipt/${guid}`);
    }

    getGuarantorTenancyAgreementByGuid(guid: string) {
        return this.http.get<GuarantorTenancyAgreement>(`${this.configService.baseUrl}/guarantor/${guid}/tenancy-agreement`);
    }

    getGuarantorTenancyAgreementByInviteReference(inviteReference: string, mobileNumber: string) {
        let params = new HttpParams();
        params = params.set('inviteReference', inviteReference);
        params = params.set('mobileNumber', mobileNumber);

        return this.http.get<GuarantorTenancyAgreement>(`${this.configService.baseUrl}/guarantor/identify`, { params: params });
    }

    guarantorSignTenancyAgreement(guid: string, model: TenancyAgreementGuarantorSignedPost) {
        let obs = of([]);

        if (model.signedFiles) {
            obs = obs.pipe(
                mergeMap(_ => this.fileService.uploadFiles(model.signedFiles)),
                tap(_ => model.signedFiles = this.fileService.prepareFilePosts(model.signedFiles)));
        }

        const url = `${this.configService.baseUrl}/tenancy-agreements/${guid}/guarantor-signature`;
        return obs.pipe(mergeMap(_ => this.http.post<GuarantorTenancyAgreement>(url, model)));
    }

    getTenanciesOverview(organisationReference: string, propertyReferences: string[] | null = null) {
        let params = new HttpParams();

        if (propertyReferences) {
            if (propertyReferences.length) propertyReferences.forEach(reference => params = params.append('propertyReferences', reference));
            else params = params.append('propertyReferences', PropertyFilterPresets.NONE);
        }

        return this.http.get<TenanciesOrganisationOverview>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/tenancies/overview`, { params: params });
    }

    getTenanciesAsCsvUrl(organisationReference: string, propertyReferences: string[] | null = null, pastTenancies: boolean = false): string {
        let params = new HttpParams();

        if (propertyReferences) {
            if (propertyReferences.length) propertyReferences.forEach(reference => params = params.append('propertyReferences', reference));
            else params = params.append('propertyReferences', PropertyFilterPresets.NONE);
        }

        params = params.append('pastTenancies', pastTenancies ? "true" : "false");
        params = params.append('access_token', this.accountService.accessToken.bearerToken);

        return`${this.configService.baseUrl}/manage/organisation/${organisationReference}/tenancies/overview/as-csv/true?${params.toString()}`;
    }

    getTenancySummaries(organisationReference: string, status: TenancyStatuses, moveInDateFrom: Date, moveInDateTo: Date, fixedTermEndDateFrom: Date, fixedTermEndDateTo: Date): Observable<TenancySummary[]> {

        let params = new HttpParams();
        if (status != null) {
            params = params.set('status', status);
        }

        if (moveInDateFrom != null) {
            params = params.set("moveInDateFrom", moment(moveInDateFrom).utc().toISOString());
        }

        if (moveInDateTo != null) {
            params = params.set("moveInDateTo", moment(moveInDateTo).utc().toISOString());
        }

        if (fixedTermEndDateFrom != null) {
            params = params.set("fixedTermEndDateFrom", moment(fixedTermEndDateFrom).utc().toISOString());
        }

        if (fixedTermEndDateTo != null) {
            params = params.set("fixedTermEndDateTo", moment(fixedTermEndDateTo).utc().toISOString());
        }

        return this.http.get<TenancySummary[]>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/tenancies`, { params: params });
    }

    moveRoom(organisationReference: string, propertyReference: string, roomReference: string, tenancyGuid: string, model: TenancyRoomMovePost) {
        return this.http.post<TenancySummary>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/property/${propertyReference}/room/${roomReference}/tenancy/${tenancyGuid}/move-room`, model);
    }

    getAgreementsInProgressForExistingTenancies(organisationReference: string) {
        return this.http.get<TenancyAgreementSummary[]>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/tenancy-agreements/in-progress`);
    }

    getTenanciesOfGroup(propertyReference: string, currentTenancyGuid: string, status: TenancyStatuses) {
        let params = new HttpParams();
        if (currentTenancyGuid) {
            params = params.set('currentTenancyGuid', currentTenancyGuid);
        }
        if (status !== undefined && status !== null) {
            params = params.set('status', status);
        }
        console.log(params);
        const url = `${this.configService.baseUrl}/manage/properties/${propertyReference}/tenancies/of-group`;
        return this.http.get<GetTenanciesOfGroupResponse>(url, {params});
    }

    checkUserSigned(tenancyAgreement: TenancyAgreement) {
        const currentUser = this.accountService.getCurrentUser();
        if(!tenancyAgreement.digitalSignature) return false;
        if(!tenancyAgreement.digitalSignature.applicantDigitalSignatureSignings) return false;
        const signingsSigned = tenancyAgreement.digitalSignature.applicantDigitalSignatureSignings.filter(m => m.signedStatus === DigitalSignatureStatuses.SIGNED);
        if(signingsSigned.some(m => m.userId === currentUser.id)) {
            return true
        } else if(signingsSigned.some(m => m.forename === currentUser.person.forename && m.surname === currentUser.person.surname)) {
            return true
        }
        return false;
    }

    allTenantsAndGuarantorsHaveSigned(tenancyAgreement: TenancyAgreement) {
        const allTenantsSigned = tenancyAgreement?.digitalSignature?.applicantDigitalSignatureSignings?.filter(m => m.dateSigned).length
            == tenancyAgreement?.digitalSignature?.applicantDigitalSignatureSignings?.length;
        const allGuarantorsSigned = tenancyAgreement?.guarantorDigitalSignature?.guarantorDigitalSignatureSignings?.filter(m => m.dateSigned).length
            == tenancyAgreement?.guarantorDigitalSignature?.guarantorDigitalSignatureSignings?.length;
        return allTenantsSigned && allGuarantorsSigned;
    }

    getESignaturePrice(organisationReference: string) {
        const url = `${this.configService.baseUrl}/manage/organisation/${organisationReference}/esignature-price`;
        return this.http.get<number>(url);
    }
}
