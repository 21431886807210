import {Component, ElementRef, ViewChild, ViewEncapsulation} from '@angular/core';
import {Subject} from 'rxjs';
import PDFJSExpress from '@pdftron/pdfjs-express-viewer';
import {ModalTypes} from '../../models/modal/modal-types';
import {ModalService} from '../../services/modal.service';
import {ModalState} from '../../models/modal/modal-state';
import {File as HouseShareFile} from '../../models/file/file';
import {AccountService} from '../../services/account.service';
import {FileTypes} from '../../models/file/file-types';
import {LazyLoaderService} from "../../services/lazy-loader.service";
import {ModalStates} from "../../models/modal/modal-states";
import {ConfigService} from "../../services/config.service";
import {WindowService} from "../../services/window.service";
import {LoadingState} from "../../models/loading-state";
import {FileService} from "../../services/file.service";
import {IconNames} from "../../models/icon-names";

@Component({
    selector: 'document-viewer-modal',
    templateUrl: './document-viewer-modal.component.html',
    encapsulation: ViewEncapsulation.None
})

export class DocumentViewerModalComponent {

    @ViewChild('pdfViewer', {static: false}) viewer: ElementRef;

    ModalTypes = ModalTypes;
    callbackSubscriber = new Subject<ModalState<HouseShareFile>>();
    file: HouseShareFile;
    url: string;
    FileTypes = FileTypes;
    loaded: boolean;
    videoSignedUrl: string;
    getSignedUrl = new LoadingState();

    constructor(
        private modalService: ModalService,
        private accountService: AccountService,
        private lazyLoaderService: LazyLoaderService,
        private configService: ConfigService,
        private windowService: WindowService,
        private fileService: FileService) {

        this.callbackSubscriber.subscribe(config => {
                if (config.state === ModalStates.OPEN) {
                    this.configureForm(config.data);
                } else {
                    this.file = null;
                }
            });
    }

    closeModal() {
        this.videoSignedUrl = null;
        this.modalService.closeModal(ModalTypes.DOCUMENT_VIEWER);
        setTimeout(_ => this.loaded = false);
    }

    configureForm(file: HouseShareFile) {
        this.file = file;
        this.url = `${this.file.url}?stream=true`;
        if(this.accountService.accessToken?.bearerToken){
            this.url += '&access_token=' + this.accountService.accessToken.bearerToken;
        }
        const guarantorToken = this.accountService.getGuarantorToken();
        if (guarantorToken != null) {
            this.url += `&guarantorToken=${guarantorToken}`;
        }

        if (this.file.type === FileTypes.DOCUMENT && this.file.extensionType.toLowerCase() === 'pdf') {
            setTimeout(_ => this.loadPdf());
        } else if (this.file.type === FileTypes.VIDEO) {
            this.getVideoSignedUrl()
        }
    }

    private loadPdf() {
        PDFJSExpress({
            path: './pdfjs-express-viewer',
            initialDoc: this.url,
            enableAnnotations: false,
            licenseKey: this.configService.pdfJsKey
        }, this.viewer.nativeElement)
            .then(instance => {
                instance.UI.setTheme(this.accountService.getCurrentUser()?.darkMode ? 'dark' : 'light');
            });
    }

    getVideoSignedUrl() {
        this.getSignedUrl.fromObservable(this.fileService.getSignedVideoUrl(this.file.reference))
            .subscribe( data => {
                this.videoSignedUrl = data.signedUrl;
            })
    }

    downloadFile() {
        const url = `${this.file.downloadUrl}?access_token=${this.accountService.accessToken.bearerToken}`;
        this.windowService.open(url);
    }

    protected readonly IconNames = IconNames;
}
