export const environment = {
    production: false,
    demo: false,
    labs: true,
    baseUrl: 'https://apilabs.coho.life',
    liveWebUrl: 'https://labs.coho.life/app',
    staticWebUrl: 'https://labs.coho.life/',
    demoWebUrl: 'https://demo.coho.life/',
    facebookPixelId: '',
    googleTagManagerId: 'GTM-TPRVV7K',
    googleTagManagerAuthKey: '',
    googleMapsToken: 'AIzaSyBD0Y3r4TJozLyjXlBNNZHGqKRXy4ivX80',
    googleAnalyticsId: 'UA-134795792-2',
    tinyMceKey: 'u32ahkcuikaio25141vzm3q29eigrspjg7yweyyra2iee0nx',
    paysafeAccountId: '1001885570',
    paysafeKey: 'dGVzdF92YW5udm9nc3RhZHRlc3Q6Qi1xYTItMC01ZmQwYTYxZi0wLTMwMmMwMjE0MTE0YWMyNDFkNjFlMzgxOTQ5MWYwYWJlMGEzZjliZmMyMWFkYjY2MzAyMTQyYjg4N2IyNzUzZjc0NjUxZTQ1MzkzMTYxNWI1YzZlZWU2OGQ2OTZm',
    logRocketKey: null,
    openReplayKey: null,
    stripeKey: 'pk_test_51HOUW5J9rKtSpEehJ40DKt5cYif8bXJoKmU7i3bk2wgUhd4ZrJwwQ3PuICA8RZMNrLNYoJK4tfZiRkQN421Qafy900N1BvIB1v',
    pdfJsKey: '6udEU9ssqMG3NzQju56B'
};
