import { Injectable } from '@angular/core';
import {Subject} from "rxjs";

import { WebsocketService } from './websocket.service';
import { ConfigService } from './config.service';
import { AccountService } from './account.service';
import { Message } from '../models/message/message';
import { NewConversationAlert } from '../models/message/new-conversation-alert';
import { MaintenanceIssue } from '../models/maintenance/maintenance-issue';
import { Viewing } from '../models/tenant-find/viewing';
import { Onboarding } from '../models/onboarding/onboarding';
import { MessageReadUpdate } from './message-read.update';
import { AutoRentMatchingUpdate } from '../models/finance/auto-rent-matching-update';
import {Conversation} from "../models/message/conversation";
import {TdsDeposit} from "../models/tds/tds-deposit";
import {InventoryTemplate} from "../models/inventory-template/inventory-template.interface";
import {GoCardlessExport} from "../models/gocardless/gocardless-export.interface";
import {OrganisationRentCollectionSettings} from "../models/rent-collection/organisation-rent-collection-settings";
import {GoCardlessRentDueRecord} from "../models/gocardless/go-cardless-rent-due-record";
import {GoCardlessTenancy} from "../models/gocardless/gocardless-tenancy";
import {Lead} from "../models/tenant-find/lead";
import {RightmoveAuthenticationInformation} from "../models/rightmove/rightmove-authentication-information.interface";
import {RightmoveProperty} from "../models/rightmove/rightmove-property.interface";

export class ConnectionMethods {
    static MESSAGE_RECEIVED = 'MessageReceived';
    static CONVERSATION_RECEIVED = 'ConversationReceived';
    static CONVERSATION_UPDATED = 'ConversationUpdated';
    static REPAIR_UPDATE_RECEIVED = 'RepairUpdateReceived';
    static VIEWING_UPDATE_RECEIVED = 'ViewingUpdateReceived';
    static ONBOARDING_UPDATE_RECEIVED = 'OnboardingUpdateReceived';
    static MANAGER_MAINTENANCE_ISSUE_UPDATE_RECEIVED = 'ManagerMaintenanceIssueUpdateReceived';
    static SUPPLIER_MAINTENANCE_ISSUE_UPDATE_RECEIVED = 'SupplierMaintenanceIssueUpdateReceived';
    static OWNER_MAINTENANCE_ISSUE_UPDATE_RECEIVED = 'OwnerMaintenanceIssueUpdateReceived';
    static TENANT_MAINTENANCE_ISSUE_UPDATE_RECEIVED = 'TenantMaintenanceIssueUpdateReceived';
    static MESSAGE_READ_UPDATE_RECEIVED = 'MessageReadUpdateReceived';
    static NEW_NOTIFICATIONS_ALERT_RECEIVED = 'NewNotificationsAlert';
    static NEW_MANAGE_ACTIONS_ALERT_RECEIVED = 'NewManageActionsAlert';
    static NEW_MANAGE_NOTIFICATIONS_ALERT_RECEIVED = 'NewManageNotificationsAlert';
    static NEW_OWNER_ACTIONS_ALERT_RECEIVED = 'NewOwnerActionsAlert';
    static NEW_OWNER_NOTIFICATIONS_ALERT_RECEIVED = 'NewOwnerNotificationsAlert';
    static NEW_SUPPLIER_ACTIONS_ALERT_RECEIVED = 'NewSupplierActionsAlert';
    static NEW_SUPPLIER_NOTIFICATIONS_ALERT_RECEIVED = 'NewSupplierNotificationsAlert';
    static NEW_MY_HOME_ACTIONS_ALERT_RECEIVED = 'NewMyHomeActionsAlert';
    static NEW_MY_HOME_NOTIFICATIONS_ALERT_RECEIVED = 'NewMyHomeNotificationsAlert';
    static ARTICLE_READ_ALERT_RECEIVED = 'ArticleRead';
    static AUTO_MATCHING_RENT_DUE_RECORDS_UPDATE = 'AutoMatchingRentDueRecordsUpdate';
    static OPEN_BANKING_RESPONSE_RECEIVED = "OpenBankingResponseReceived"
    static TDS_DEPOSIT_UPDATE_RECEIVED = "TdsDepositUpdateReceived"
    static INVENTORY_TEMPLATE_CREATED = 'InventoryTemplateCreated';
    static INVENTORY_TEMPLATE_UPDATED = 'InventoryTemplateUpdated';
    static INVENTORY_TEMPLATE_ARCHIVED = 'InventoryTemplateArchived';
    static GO_CARDLESS_EXPORT_CREATED = 'GoCardlessExportCreated';
    static MESSAGE_DELETED = 'MessageDeleted';
    static MANAGE_ACTIONS_UPDATE_PING = 'ManageActionsUpdatePing';
    static RightmoveAuthUpdated = 'RightmoveAuthUpdated';
    static RightmoveAuthDeleted = 'RightmoveAuthDeleted';
    static RightmovePropertyUpdated = 'RightmovePropertyUpdated';
    static RightmovePropertyDeleted = 'RightmovePropertyDeleted';
    static NewLead = 'NewLead';
    static OrganisationRentCollectionSettingsUpdated = 'OrganisationRentCollectionSettingsUpdated';
    static GoCardlessRentDueRecordUpdated = 'GoCardlessRentDueRecordUpdated';
    static GoCardlessTenancyUpdated = 'GoCardlessTenancyUpdated';
}

@Injectable({
    providedIn: 'root'
})
export class WebsocketNotificationService extends WebsocketService {
    messageReceived = new Subject<Message>();
    conversationReceived = new Subject<NewConversationAlert>();
    conversationUpdateReceived = new Subject<Conversation>();
    repairUpdateReceived = new Subject<MaintenanceIssue>();
    viewingUpdateReceived = new Subject<Viewing>();
    onboardingUpdateReceived = new Subject<Onboarding>();
    managerMaintenanceIssueUpdateReceived = new Subject<MaintenanceIssue>();
    supplierMaintenanceIssueUpdateReceived = new Subject<MaintenanceIssue>();
    ownerMaintenanceIssueUpdateReceived = new Subject<MaintenanceIssue>();
    tenantMaintenanceIssueUpdateReceived = new Subject<MaintenanceIssue>();
    messageReadUpdateReceived = new Subject<MessageReadUpdate>();
    newNotificationsAlertReceived = new Subject<boolean>();
    newManageActionsAlertReceived = new Subject<boolean>();
    newManageNotificationsAlertReceived = new Subject<boolean>();
    newOwnerActionsAlertReceived = new Subject<boolean>();
    newOwnerNotificationsAlertReceived = new Subject<boolean>();
    newSupplierActionsAlertReceived = new Subject<boolean>();
    newSupplierNotificationsAlertReceived = new Subject<boolean>();
    newMyHomeActionsAlertReceived = new Subject<boolean>();
    newMyHomeNotificationsAlertReceived = new Subject<boolean>();
    articleReadReceived = new Subject<string>();
    autoMatchingRentDueRecordsUpdateReceived = new Subject<AutoRentMatchingUpdate>();
    openBankingResponseReceived = new Subject<string>();
    TdsDepositUpdateReceived = new Subject<TdsDeposit>();
    inventoryTemplateCreated = new Subject<InventoryTemplate>();
    inventoryTemplateUpdated = new Subject<InventoryTemplate>();
    inventoryTemplateArchived = new Subject<InventoryTemplate>()
    goCardlessExportCreated = new Subject<GoCardlessExport>()
    messageDeleted = new Subject<Message>();
    manageActionsUpdatePing = new Subject<boolean>();
    organisationRentCollectionSettingsUpdated = new Subject<OrganisationRentCollectionSettings>();
    goCardlessRentDueRecordUpdated = new Subject<GoCardlessRentDueRecord>()
    goCardlessTenancyUpdated = new Subject<GoCardlessTenancy>();
    rightmoveAuthUpdated = new Subject<RightmoveAuthenticationInformation>();
    rightmoveAuthDeleted = new Subject<RightmoveAuthenticationInformation>();
    rightmovePropertyUpdated = new Subject<RightmoveProperty>();
    rightmovePropertyDeleted = new Subject<RightmoveProperty>();
    newLead = new Subject<Lead>();

    fallbackTimeoutSubject = new Subject<{ connectionMethod: ConnectionMethods, triggered: boolean, objectId: string }>(); // Updated subject type
    private fallbackTimeouts: { [key: string]: any } = {}; // Dictionary to track timeouts

    constructor(configService: ConfigService, accountService: AccountService) {
        super(configService, accountService);

        this.createConnection('/hubs/notifications');
        this.registerOnServerEvents();
        this.startConnection();
    }

    private registerOnServerEvents(): void {
        Object.keys(ConnectionMethods).forEach(key => this._hubConnection.off(key));

        this._hubConnection.on(ConnectionMethods.MESSAGE_RECEIVED, (data: Message) => {
            this.messageReceived.next(data);
        });

        this._hubConnection.on(ConnectionMethods.CONVERSATION_RECEIVED, (data: NewConversationAlert) => {
            this.conversationReceived.next(data);
        });

        this._hubConnection.on(ConnectionMethods.CONVERSATION_UPDATED, (data: Conversation) => {
            this.conversationUpdateReceived.next(data);
        });

        this._hubConnection.on(ConnectionMethods.REPAIR_UPDATE_RECEIVED, (data: MaintenanceIssue) => {
            this.repairUpdateReceived.next(data);
        });

        this._hubConnection.on(ConnectionMethods.VIEWING_UPDATE_RECEIVED, (data: Viewing) => {
            console.log('viewing update received', data)
            this.clearFallbackTimeout(ConnectionMethods.VIEWING_UPDATE_RECEIVED)
            this.viewingUpdateReceived.next(data);
        });

        this._hubConnection.on(ConnectionMethods.ONBOARDING_UPDATE_RECEIVED, (data: Onboarding) => {
            this.onboardingUpdateReceived.next(data);
        });

        this._hubConnection.on(ConnectionMethods.MANAGER_MAINTENANCE_ISSUE_UPDATE_RECEIVED, (data: MaintenanceIssue) => {
            this.managerMaintenanceIssueUpdateReceived.next(data);
        });

        this._hubConnection.on(ConnectionMethods.OWNER_MAINTENANCE_ISSUE_UPDATE_RECEIVED, (data: MaintenanceIssue) => {
            this.ownerMaintenanceIssueUpdateReceived.next(data);
        });

        this._hubConnection.on(ConnectionMethods.TENANT_MAINTENANCE_ISSUE_UPDATE_RECEIVED, (data: MaintenanceIssue) => {
            this.tenantMaintenanceIssueUpdateReceived.next(data);
        });

        this._hubConnection.on(ConnectionMethods.SUPPLIER_MAINTENANCE_ISSUE_UPDATE_RECEIVED, (data: MaintenanceIssue) => {
            this.supplierMaintenanceIssueUpdateReceived.next(data);
        });

        this._hubConnection.on(ConnectionMethods.MESSAGE_READ_UPDATE_RECEIVED, (data: MessageReadUpdate) => {
            this.messageReadUpdateReceived.next(data);
        });

        this._hubConnection.on(ConnectionMethods.NEW_NOTIFICATIONS_ALERT_RECEIVED, (data: boolean) => {
            console.log('newNotificationsAlertReceived', data);
            this.newNotificationsAlertReceived.next(data);
        });

        this._hubConnection.on(ConnectionMethods.NEW_MANAGE_ACTIONS_ALERT_RECEIVED, (data: boolean) => {
            this.newManageActionsAlertReceived.next(data);
        });

        this._hubConnection.on(ConnectionMethods.MANAGE_ACTIONS_UPDATE_PING, (data: boolean) => {
            this.manageActionsUpdatePing.next(data);
            console.log('ping action update');
        });

        this._hubConnection.on(ConnectionMethods.NEW_MANAGE_NOTIFICATIONS_ALERT_RECEIVED, (data: boolean) => {
            this.newManageNotificationsAlertReceived.next(data);
        });

        this._hubConnection.on(ConnectionMethods.NEW_OWNER_ACTIONS_ALERT_RECEIVED, (data: boolean) => {
            this.newOwnerActionsAlertReceived.next(data);
        });

        this._hubConnection.on(ConnectionMethods.NEW_SUPPLIER_ACTIONS_ALERT_RECEIVED, (data: boolean) => {
            this.newSupplierActionsAlertReceived.next(data);
        });
        this._hubConnection.on(ConnectionMethods.NEW_OWNER_NOTIFICATIONS_ALERT_RECEIVED, (data: boolean) => {
            this.newOwnerNotificationsAlertReceived.next(data);
        });

        this._hubConnection.on(ConnectionMethods.NEW_SUPPLIER_NOTIFICATIONS_ALERT_RECEIVED, (data: boolean) => {
            this.newSupplierNotificationsAlertReceived.next(data);
        });

        this._hubConnection.on(ConnectionMethods.NEW_MY_HOME_ACTIONS_ALERT_RECEIVED, (data: boolean) => {
            this.newMyHomeActionsAlertReceived.next(data);
        });

        this._hubConnection.on(ConnectionMethods.NEW_MY_HOME_NOTIFICATIONS_ALERT_RECEIVED, (data: boolean) => {
            this.newMyHomeNotificationsAlertReceived.next(data);
        });

        this._hubConnection.on(ConnectionMethods.ARTICLE_READ_ALERT_RECEIVED, (articleReference: string) => {
            this.articleReadReceived.next(articleReference);
        });

        this._hubConnection.on(ConnectionMethods.AUTO_MATCHING_RENT_DUE_RECORDS_UPDATE, (data: AutoRentMatchingUpdate) => {
            this.autoMatchingRentDueRecordsUpdateReceived.next(data);
        });

        this._hubConnection.on(ConnectionMethods.OPEN_BANKING_RESPONSE_RECEIVED, (data: string) => {
            this.openBankingResponseReceived.next(data);
        })

        this._hubConnection.on(ConnectionMethods.TDS_DEPOSIT_UPDATE_RECEIVED, (data: TdsDeposit) => {
            this.TdsDepositUpdateReceived.next(data);
            this.clearFallbackTimeout(ConnectionMethods.TDS_DEPOSIT_UPDATE_RECEIVED);
        });

        this._hubConnection.on(ConnectionMethods.INVENTORY_TEMPLATE_CREATED, (data: InventoryTemplate) => {
            this.inventoryTemplateCreated.next(data);
        })

        this._hubConnection.on(ConnectionMethods.INVENTORY_TEMPLATE_UPDATED, (data: InventoryTemplate) => {
            this.inventoryTemplateUpdated.next(data);
        })

        this._hubConnection.on(ConnectionMethods.INVENTORY_TEMPLATE_ARCHIVED, (data: InventoryTemplate) => {
            this.inventoryTemplateArchived.next(data);
        })

        this._hubConnection.on(ConnectionMethods.GO_CARDLESS_EXPORT_CREATED, (data: GoCardlessExport) => {
            console.log('goCardlessExportCreated websocket reception', data)
            this.goCardlessExportCreated.next(data);
        })

        this._hubConnection.on(ConnectionMethods.MESSAGE_DELETED, (data: Message) => {
            console.log('message deleted', data)
            this.messageDeleted.next(data);
        });

        this._hubConnection.on(ConnectionMethods.OrganisationRentCollectionSettingsUpdated, (data: OrganisationRentCollectionSettings) => {
            console.log('OrganisationRentCollectionSettings update websocket event', data)
            this.organisationRentCollectionSettingsUpdated.next(data);
        });

        this._hubConnection.on(ConnectionMethods.GoCardlessRentDueRecordUpdated, (data: GoCardlessRentDueRecord) => {
            console.log('GoCardlessRentDueRecord update websocket event', data)
            this.goCardlessRentDueRecordUpdated.next(data);
        });

        this._hubConnection.on(ConnectionMethods.GoCardlessTenancyUpdated, (data: GoCardlessTenancy) => {
            console.log('GoCardlessTenancy update websocket event', data)
            this.goCardlessTenancyUpdated.next(data);
        });

        this._hubConnection.on(ConnectionMethods.RightmoveAuthUpdated, (data: RightmoveAuthenticationInformation) => {
            this.rightmoveAuthUpdated.next(data);
        });

        this._hubConnection.on(ConnectionMethods.RightmoveAuthDeleted, (data: RightmoveAuthenticationInformation) => {
            this.rightmoveAuthDeleted.next(data);
        });

        this._hubConnection.on(ConnectionMethods.RightmovePropertyUpdated, (data: RightmoveProperty) => {
            this.rightmovePropertyUpdated.next(data);
        });

        this._hubConnection.on(ConnectionMethods.RightmovePropertyDeleted, (data: RightmoveProperty) => {
            this.rightmovePropertyDeleted.next(data);
        });

        this._hubConnection.on(ConnectionMethods.NewLead, (data: Lead) => {
            this.newLead.next(data);
        });
    }

    public onOperationInitiated(connectionMethod: string, objectId: string) {
        this.setFallbackTimeout(connectionMethod, objectId);
    }

    private setFallbackTimeout(connectionMethod: string, objectId: string) {
        this.fallbackTimeouts[connectionMethod] = setTimeout(() => {
            this.fallbackTimeoutSubject.next({ connectionMethod, triggered: true, objectId: objectId }); // Emit event when timeout occurs
        }, 5000); // 5 seconds timeout: if nothing is received within the 5 seconds after we got the Ok reponse
    }

    private clearFallbackTimeout(connectionMethod: string) {
        if (this.fallbackTimeouts[connectionMethod]) {
            clearTimeout(this.fallbackTimeouts[connectionMethod]);
            delete this.fallbackTimeouts[connectionMethod];
        }
    }
}
