import { Component, ViewEncapsulation, Input, forwardRef, ViewChild, ElementRef } from '@angular/core';
import { ControlContainer, NG_VALUE_ACCESSOR } from '@angular/forms';

import { BaseControlValueAccessor } from '../../models/forms/base-control-value-accessor';
import { UtilitiesService } from "../../services/utilities.service";
import { DropdownOption } from "./dropdown-option";
import { Guid } from "../../models/guid/guid";

@Component({
    selector: 'dropdown',
    templateUrl: './dropdown.component.html',
    encapsulation: ViewEncapsulation.None,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DropdownComponent),
            multi: true
        }
    ]
})

export class DropdownComponent extends BaseControlValueAccessor<any> {

    placeholderValue: string = Guid.newGuid();

    @Input() placeholder: string = '';
    @Input() cssClasses: string = null;
    @Input() options: DropdownOption[];

    @Input() set readonly(value: boolean) {
        this._readonly = value;
        this.setDisabled();
    }

    @Input() set disabled(value: boolean) {
        this._disabled = value;
        this.setDisabled();
    }

    setDisabledState(value: boolean) {
        this._disabled = value;
        this.setDisabled();
    }

    @ViewChild('append', { static: true }) append: ElementRef;
    @ViewChild('dropdown', {static: true}) dropdownElement: ElementRef;

    constructor(private element: ElementRef, protected controlContainer: ControlContainer, protected utilitiesService: UtilitiesService) {
        super(controlContainer, utilitiesService);
    }

    ngOnInit(): void {
        super.ngOnInit();

        this.readonly = this._readonly;
        this.disabled = this._disabled;

        this.value = this.placeholderValue;
    }

    internalOnChange(event) {
        let val = event.target.value;
        if (val === 'null') {
            val = null;
        }

        this.onChange(val);
    }

    writeValue(value: any): void {
        if (this.placeholder && (value === undefined || value === null)) {
            value = this.placeholderValue;
        }

        super.writeValue(value);
    }

    private setDisabled() {
        if (this.dropdownElement && this.dropdownElement.nativeElement) {
            this.dropdownElement.nativeElement.disabled = this._disabled || this._readonly;
        }
    }
}
