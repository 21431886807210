import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ConfigService } from './config.service';
import { ManageRoomCompliancyOverview } from '../models/compliancy/manage-room-compliancy-overview';
import { ManageRoomCompliancyItem } from '../models/compliancy/manage-room-compliancy-item';
import { MyCompliancyOverview } from '../models/compliancy/my-compliancy-overview';
import { CompliancyItem } from '../models/compliancy/compliancy-item';
import {OrganisationCompliancyOverview} from "../models/compliancy/organisation-compliancy-overview";
import {AccountService} from "./account.service";
import {HowToRentGuideInterface} from "../models/how-to-rent/how-to-rent-guide.interface";


@Injectable()
export class CompliancyService  {
    constructor(private http: HttpClient, private configService: ConfigService, private accountService: AccountService) {
    }

    getOrganisationOverview(organisationReference: string, daysToExpiry: number = null): Observable<OrganisationCompliancyOverview> {
        let params = new HttpParams();
        if (daysToExpiry != null) {
            params = params.set('daysToExpiry', daysToExpiry);
        }

        return this.http.get<OrganisationCompliancyOverview>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/compliancy/overview`, { params });
    }

    getOverview(propertyReference: string, roomReference: string, tenancyGuid: string = null): Observable<ManageRoomCompliancyOverview> {
        let params = new HttpParams();
        if (tenancyGuid) {
            params = params.set('tenancyGuid', tenancyGuid);
        }

        return this.http.get<ManageRoomCompliancyOverview>(`${this.configService.baseUrl}/manage/property/${propertyReference}/room/${roomReference}/compliancy/overview`, { params });
    }

    getMyHomeOverview(tenancyGuid: string = null): Observable<MyCompliancyOverview> {
        let params = new HttpParams();
        if (tenancyGuid) {
            params = params.set('tenancyGuid', tenancyGuid);
        }

        return this.http.get<MyCompliancyOverview>(`${this.configService.baseUrl}/my-home/compliancy/overview`, { params });
    }

    getManageTenancyCompliancyItems(tenancyGuid: string): Observable<CompliancyItem[]> {
        const url = `${this.configService.baseUrl}/manage/tenancy/${tenancyGuid}/compliancy`
        return this.http.get<CompliancyItem[]>(url);
    }

    getManageTenancyInvalidCompliancyItems(organisationReference: string, propertyReference: string, roomReference: string, tenancyGuid: string): Observable<string[]> {
        return this.http.get<string[]>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/property/${propertyReference}/room/${roomReference}/tenancy/${tenancyGuid}/invalid-compliancy`);
    }

    getMyTenancyCompliancyItems(tenancyGuid: string): Observable<CompliancyItem[]> {
        return this.http.get<CompliancyItem[]>(`${this.configService.baseUrl}/my-home/tenancy/${tenancyGuid}/compliancy`);
    }

    manuallyAcknowledge(propertyReference: string, roomReference: string, itemGuid: string): Observable<ManageRoomCompliancyItem> {
        return this.http.post<ManageRoomCompliancyItem>(`${this.configService.baseUrl}/manage/property/${propertyReference}/room/${roomReference}/compliancy/${itemGuid}/manually-acknowledge`, null);
    }

    serveAgain(propertyReference: string, roomReference: string, itemGuid: string): Observable<ManageRoomCompliancyItem> {
        return this.http.post<ManageRoomCompliancyItem>(`${this.configService.baseUrl}/manage/property/${propertyReference}/room/${roomReference}/compliancy/${itemGuid}/serve`, null);
    }

    tenantAcknowledgeCompliancyItem(itemGuid: string): Observable<CompliancyItem> {
        return this.http.post<CompliancyItem>(`${this.configService.baseUrl}/my-home/compliancy/${itemGuid}/acknowledge`, null);
    }

    getTenantCompliancyGasSafetyCertificate(guid: string): Observable<CompliancyItem> {
        return this.http.get<CompliancyItem>(`${this.configService.baseUrl}/my-home/compliancy/gas-safety-certificate/${guid}`);
    }

    getTenantCompliancyEnergyPerformanceCertificate(guid: string): Observable<CompliancyItem> {
        return this.http.get<CompliancyItem>(`${this.configService.baseUrl}/my-home/compliancy/energy-performance-certificate/${guid}`);
    }

    getTenantCompliancyElectricalInstallationConditionReport(guid: string): Observable<CompliancyItem> {
        return this.http.get<CompliancyItem>(`${this.configService.baseUrl}/my-home/compliancy/electrical-installation-condition-report/${guid}`);
    }

    getTenantCompliancyHMOLicence(guid: string): Observable<CompliancyItem> {
        return this.http.get<CompliancyItem>(`${this.configService.baseUrl}/my-home/compliancy/hmo-licence/${guid}`);
    }

    getTenantCompliancyDepositManagement(guid: string): Observable<CompliancyItem> {
        return this.http.get<CompliancyItem>(`${this.configService.baseUrl}/my-home/compliancy/deposit-management/${guid}`);
    }

    getTenantCompliancyHowToRentGuide(guid: string): Observable<CompliancyItem> {
        return this.http.get<CompliancyItem>(`${this.configService.baseUrl}/my-home/compliancy/how-to-rent-guide/${guid}`);
    }

    getCompliancyItems(organisationReference: string, complianceCategoryReference: string, childComplianceCategoryReference: string = null, daysToExpiry: number = null): Observable<CompliancyItem[]> {
        let params = new HttpParams();

        if (childComplianceCategoryReference != null) {
            params = params.set('childComplianceTypeReference', childComplianceCategoryReference);
        }

        if (daysToExpiry != null) {
            params = params.set('daysToExpiry', daysToExpiry);
        }

        let url = `${this.configService.baseUrl}/manage/organisation/${organisationReference}/compliancy/types`;
        if (complianceCategoryReference != null) {
            url += `/${complianceCategoryReference}`;
        }

        return this.http.get<CompliancyItem[]>(url, { params });
    }

    getCompliancyItemsCsv(organisationReference: string, complianceCategoryReference: string | null = null, childComplianceCategoryReference: string = null, daysToExpiry: number = null): string {
        let params = new HttpParams();


        if (childComplianceCategoryReference != null) {
            params = params.set('childComplianceTypeReference', childComplianceCategoryReference);
        }

        if (daysToExpiry != null) {
            params = params.set('daysToExpiry', daysToExpiry);
        }

        params = params.set('access_token', this.accountService.accessToken.bearerToken);
        params = params.set('asCsv', true);

        let url = `${this.configService.baseUrl}/manage/organisation/${organisationReference}/compliancy/types`;
        if (complianceCategoryReference != null) {
            url += `/${complianceCategoryReference}`;
        }

        return `${url}?${params.toString()}`;
    }

    getHowToRentGuide(organisationReference:string, propertyReference: string){
        const url = `${this.configService.baseUrl}/manage/organisation/${organisationReference}/property/${propertyReference}/compliancy/how-to-rent-guide`;
        return this.http.get<HowToRentGuideInterface>(url);
    }

    downloadComplianceReport(organisationReference: string, propertyReference: string, roomReference: string, tenancyGuid: string): string {
        let params = new HttpParams();
        params = params.append('access_token', this.accountService.accessToken.bearerToken);
        return `${this.configService.baseUrl}/manage/organisation/${organisationReference}/property/${propertyReference}/room/${roomReference}/tenancy/${tenancyGuid}/download-compliance-report?${params.toString()}`;
    }
}
